const PHRASES = [
    "Press F to pay respects",
    "Bruh moment",
    "You hate to see it",
    "It be like that sometimes",
    "Things are about to get GRUsome",
    "Just kidding... unless??",
    "It's the move!",
    "3275.95 MPH",
    "Me and the boys",
    "You guys are getting paid?",
    "I'll take your entire stock",
    "That's how mafia works",
    "Not all heros wear capes",
    "I am sickened but curious",
    "Perfectly balanced",
    "Now that's a lotta damage",
    "Thank you Kanye, very cool!",
    "Weird flex, but ok",
    "You're watching Disney channel",
    "This is fine.",
    "Congratulations, you played yourself",
    "I am the one who knocks"
]

export default PHRASES;